import React from "react"
import { Collapse } from 'antd'
import SiteLayout from "../components/SiteLayout";
const Panel = Collapse.Panel;

// @ts-ignore
import styles from './jobb.module.scss';

export default ({ children }) => (
    <SiteLayout>
        <div className={styles.smallcontainer}>

            <h1>Jobb hos Oss</h1>

            <div className={styles.introtext}>Oss er et tek-selskap med store ambisjoner. Vil du bli en av Oss?
            <br></br> Ledige stillinger blir publisert fortløpende.
            </div>
            <div style={{width: "100%"}}>
            <Collapse bordered={false}>
            {/* <Panel header="Daglig Leder" key="1" className={styles.panel}>
    <div className={styles.text}>
        <p>
        Vår nye daglige leder vil få ansvaret for selskapets videre utvikling, kommersialisering og vekst. Med to store og solide aktører på eiersiden vil selskapet kunne arbeide med kreative og innovative løsninger kombinert med målrettet og lønnsom vekst. Vi ser etter en leder som kan skape resultater og engasjement blant ansatte, kunder, eiere og samarbeidspartnere

        </p>
        <p>Er du en som synes software utvikling er det morsomste man kan gjøre, og som vil være med å utvikle teknologi
            for fremtiden! Da vil vi gjerne ha en prat med deg!
        </p>
        <p>Kontakt og søknadsinfo finner du <a href="https://www.finn.no/job/management/ad.html?finnkode=168919651">her</a>.</p>
        <p><strong>Ansvar og utvalgte arbeidsoppgaver:</strong></p>
        <ul>
        <li>Ansvar for selskapets daglige drift, kommersialisering og videre vekst</li>
        <li>Kontinuerlig videreutvikling av strategi- og forretningsplan</li>
        <li>Ansvar for å realisere strategi- og forretningsplan</li>
        <li>Ansvar for økonomi og resultatoppnåelse</li>
        <li>Lede og videreutvikle et team av engasjerte og motiverte medarbeidere</li>
        <li>Lede arbeidet med produkt- og konseptutvikling</li>
        <li>Utarbeide analyser og beslutningsunderlag samt rapportere relevante forhold til styret</li>
        </ul>
        

        <p><strong>Kvalifikasjoner og personlige egenskaper:</strong></p>
        <ul>
        <li>Relevant utdannelse, gjerne innen entreprenørskap, industriell økonomi e.l</li>
        <li>Kommersiell legning og solid forretningsforståelse</li>
        <li>Teknologisk innsikt og digital kompetanse</li>
        <li>Strukturert og resultatorientert</li>
        <li>Sterke lederegenskaper med evne til å skape engasjement</li>
        <li>Gode relasjon- og samarbeidsevner</li>
        <li>Gode språkferdighet norsk og engelsk, muntlig og skriftlig</li>
        <li>Erfaring fra oppstartselskaper og produktutvikling er en fordel</li>
        </ul>
        

        <p><strong>Vi tilbyr:</strong></p>
        <ul>
            <li>En mulighet til å utforme fremtidens digitale strømtjenester</li>
            <li>Spennende og svært varierte arbeidsoppgaver</li>
            <li>Konkurransedyktige betingelser</li>
            <li>Topp moderne lokaler sentralt i Drammen</li>
            <li>Faglig sterkt og sosialt hyggelig arbeidsmiljø</li>
        </ul>

      

       
      



        <p> Stillingen rapporterer til selskapets styre. Arbeidsted sentralt i Drammen. Fleksibel arbeidssituasjon vil være nødvendig.
        </p>
        <p>Ta gjerne kontakt med styreleder Steinar Åril på mobil 932 43 774 eller Lars Hagen, Nye Ansatte AS på 90 999 000.
        </p>
        <p>Alle henvendelser blir behandlet strengt konfidensielt. All innsendt informasjon vil bli behandlet i henhold til gjeldende regelverk.
        </p>

        <p><strong>Om Oss</strong></p>
        <p>
        Glitre Energi og Agder Energi slår sammen sine digitale satsninger med mål om å levere tjenester for fremtidens strømkunder.
        </p>
        <p>For å oppnå dette må vi tørre å tenke nytt, utfordre det eksisterende, og sette en ny standard for bransjen med fokus på gode digitale tjenester og kundeopplevelser. Ny teknologi gir nye muligheter, samtidig som kundene stadig får høyere forventninger til sin leverandør. For å møte fremtidens forventninger har både Agder Energi og Glitre Energi jobbet med digitale nysatsninger med nettopp den målsetningen.
Glitre Energi har etablert selskapet Oss Norge AS, bestående av 8 fulltidsansatte, som har utviklet en teknologi som tilgjengeliggjør og bygger tjenester på sanntidsdata fra de nye smartmålerne. Agder Energi lanserte våren 2019 en testversjon av sin nye digitale strømleverandør, Flyt. Agder Energi og Glitre Energi har nå valgt å slå sammen disse to sansningene, til en felles sterk satsning. Etter sammenslåingen er Oss Norge rigget sterkere enn noen gang, klar for å ta fatt på fremtidens muligheter. Derfor søker vi nå etter en fremoverlent, entusiastisk og nyskapende leder som vil være med å skape fremtiden.</p>
    </div>
</Panel>
            <Panel header="Senior systemutvikler/arkitekt" key="2" className={styles.panel}>
    <div className={styles.text}>
        <p>
            Er du gira på å gjøre en forskjell, og å bli en del av et team med ambisjoner om å være helt i front på ny
            teknologi? Er du en som kan drømme deg bort i kodelinjer og løsningsarkitektur?
        </p>
        <p>Er du en som synes software utvikling er det morsomste man kan gjøre, og som vil være med å utvikle teknologi
            for fremtiden! Da vil vi gjerne ha en prat med deg!
        </p>

        <p><strong>Hvem leter vi etter?</strong></p>
        <p>
            Vi søker nå flere utviklere som elsker å skape, og som liker å utfordre. Fra det mest tekniske til det
            kommersielle. Vi trenger deg som er effektiv og kan levere raskt, og som vet hvilke kombinasjoner av egen,
            åpen og kommersiell kode og løsninger som tar oss raskest og best i mål. Vi søker deg som følger med i
            teknologiutviklingen og som kan vise til tidligere arbeid som er litt utenom det vanlige.
        </p>
        <p>
            Oss Norge er i rask utvikling. I takt med etterspørselen i markedet skal vi utvikle tjenester som
            revolusjonerer kundens forhold til strøm. Vi skal tilby en tjeneste som trygger og forenkler kundens
            hverdag. Du som blir ansatt vil være med på å påvirke og forme denne reisen videre.
        </p>

        <p><strong>Kompetanseområder vi ser som interessante:</strong></p>
        <ul>
            <li>Skytjenester</li>
            <li>SaaS</li>
            <li>PaaS</li>
            <li>Azure / AWS</li>
            <li>Docker / Kubernetes</li>
            <li>.Net Core</li>
            <li>C#</li>
            <li>Maskinlæring</li>
        </ul>
        <p>Dette er ønskelisten, vi forventer ikke at du kan sjekke av for alt. </p>

        <p><strong>Personlige egenskaper:</strong></p>
        <ul>
            <li>Omgjengelig og hyggelig</li>
            <li>Høy indre motivasjon</li>
            <li>Kreativ</li>
            <li>Løsningsorientert</li>
            <li>Selvstendig </li>
            <li>Pålitelig </li>
        </ul>

        <p><strong>Vi tilbyr:</strong></p>
        <ul>
            <li>Fantastiske kolleger</li>
            <li>En god mix av spennende utfordringer og arbeidsoppgaver</li>
            <li>Gode utviklingsmuligheter</li>
            <li>Konkurransedyktige betingelser</li>
            <li>Topp moderne lokaler sentralt i Drammen</li>
            <li>Faglig sterkt og sosialt hyggelig arbeidsmiljø</li>
            <li>100 % fast stilling</li>
            <li>Fleksibel arbeidstid, m.m.</li>
        </ul>

        <p><strong>Vil du bli en av Oss?</strong></p>
        <p>Send en søknad til oss på jobb@oss.no. Skriv litt om hvem du er, hva du kan og hva du vil tilføre oss. Send
            med en CV eller link til din profil på LinkedIn.
        </p>
        <p>Har du noen spørsmål, ta kontakt med daglig leder Janne Blaasvær på telefon 95 70 82 52
        </p>

        <p><strong>Om Oss</strong></p>
        <p>
            Oss er et helt ferskt tek-selskap med store ambisjoner. Vi er de første til å utvikle en helt ny teknologi
            som enkelt gir vanlige forbrukere tilgang og eierskap til dataen fra de nye automatiske strømmålerne. Sammen
            med brukerne våre skal vi utvikle løsninger som med utgangspunkt i denne dataen vil trygge og forenkle
            hverdagen, og full innsikt i strømforbruket sitt.
        </p>
        <p>Les mer på <a href="https://oss.no/">oss.no</a></p>
    </div>
</Panel> */}
            
                {/* <Panel header="Åpen søknad" key="3" className={styles.panel}>
                <div className={styles.text}>
                    <p>
                        <strong>Bare MÅ du jobbe hos Oss?</strong>
                    </p>
                    <p>Drømmer du om å jobbe i et nytt heldigitalt og datadrevet selskap som skal utvikle teknologi for fremtiden? Liker
                        du tanken på å skape noe helt fra scratch?</p>

                    <p>Oss er et nystartet tek-selskap med store ambisjoner. Vi skal utvikle en helt ny løsning for strømbransjen med
                        fokus på å levere den beste og mest sømløse digitale kundeopplevelsen en strømkunde har hatt. Vi har en unik
                        idé og vi trenger din hjelp! </p>

                    <p>Hos Oss får du jobbe med utviklingen av en innovativ tjeneste helt fra starten av. Du får jobbe i et team med dyktige
                        mennesker som har lang erfaring fra både bransjen og med nyutvikling.</p>
                    <p>
                        <strong>Lyst til å jobbe med oss?</strong>
                    </p>
                    <p>Send en søknad til oss på jobb@oss.no. Skriv litt om deg selv, hva du kan bidra med og hva du forventer
                        deg. Send med en CV eller link til din profil på LinkedIn.</p>
                    <p>Har du noen spørsmål, ta kontakt med daglig leder Janne Blaasvær på telefon 957 08 252
                    </p>
                    </div>
                </Panel> */}
            </Collapse></div>
        </div>
    </SiteLayout>
)